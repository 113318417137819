import {BMFinanceService} from "@/common/api/bm_finance.service";

import {
  BM_FINANCE_GET, BM_FINANCE_SEARCH, BM_FINANCES_GET
} from "./actions/bm_finance.type";
import {
  SET_BM_FINANCE_PAGINATION, SET_BM_FINANCES, SET_BM_FINANCE, BM_FINANCE_RESET_STATE
} from "./mutations/bm_finance.type";

import {CHANGE_PROGRESS} from "./mutations/mutations.type";

const initialState = () => {
  return {
    bmWithdrawalCash: {
      id: 0,
      bmID: 0,
      companyName: "",
      cnpj: "",
      pixkey: "",
      valueReleased: 0,
      valueAnticipation: 0,
      valueRequested: 0,
      totalValue: "",
      requestDate: "",
      payDate: null,
      requested: null,
      paid: null
    },
    bmFinancePagination: {
      search: "",
      itemsPerPage: 10,
      orderBy: "",
      sortDesc: false,
    },
    bmFinances: {
      active: true,
      activePage: 1,
      totalPages: 1,
      totalItems: 1,
      items: []
    },
  };
};

export const state = initialState();

const getters = {
  bmWithdrawalCash: state => {
    return state.bmWithdrawalCash;
  },
  bmFinancePagination: state => {
    return state.bmFinancePagination;
  },
  bmFinances: state => {
    return state.bmFinances;
  }
};

export const mutations = {
  [SET_BM_FINANCE_PAGINATION](state, data) {
    state.bmFinances.activePage = data.page
    state.bmFinancePagination.itemsPerPage = data.itemsPerPage
    state.bmFinancePagination.orderBy = data.orderBy
    state.bmFinancePagination.sortDesc = data.sortDesc
  },
  [SET_BM_FINANCES](state, data) {

    if (data == null) {
      data = []
    }

    data.sort((a, b) => {
      if (a.paid === b.paid) {
        // If 'paid' is the same, compare by 'requestDate'
        if (a.requestDate === b.requestDate) {
          return 0;
        } else if (a.requestDate < b.requestDate) {
          return -1;
        } else {
          return 1;
        }
      } else {
        // Sort by 'paid' with false first
        if (a.paid === false) {
          return -1;
        } else {
          return 1;
        }
      }
    });

    data.forEach((item) => {
        item.valueAnticipation = item.valueAnticipation ? parseFloat(item.valueAnticipation) : 0;
        item.valueReleased = item.valueReleased ? parseFloat(item.valueReleased) : 0;
        item.valueRequested = item.valueRequested ? parseFloat(item.valueRequested) : 0;
        item.valueRequested = item.valueAnticipation + item.valueReleased
      }
    );

    state.bmFinances.items = data;
  },
  [SET_BM_FINANCE](state, data) {
    state.bmWithdrawalCash = data;
  },
  [BM_FINANCE_RESET_STATE](state) {
    Object.assign(state, initialState());
  },
};

export const actions = {
  async [BM_FINANCES_GET]({commit}) {
    commit(CHANGE_PROGRESS, true);
    const {data} = await BMFinanceService.listWithdrawalRequests();
    commit(SET_BM_FINANCES, data);
    commit(CHANGE_PROGRESS, false);
  },
  async [BM_FINANCE_SEARCH]({commit, state}) {
    commit(CHANGE_PROGRESS, true)
    const {data} = await BMFinanceService.listWithdrawalRequests(
      state.bmFinances.activePage,
      state.bmFinancePagination.itemsPerPage,
      state.bmFinances.active,
      state.bmFinancePagination.search,
      state.bmFinancePagination.orderBy,
      state.bmFinancePagination.sortDesc)
    commit(SET_BM_FINANCES, data)
    commit(CHANGE_PROGRESS, false)
  },

  async [BM_FINANCE_GET]({commit}, id) {
    commit(CHANGE_PROGRESS, true);
    const {data} = await BMFinanceService.getWithdrawalRequestByID(id);
    commit(SET_BM_FINANCE, data);
    commit(CHANGE_PROGRESS, false);
  }
};
export default {
  state,
  actions,
  mutations,
  getters
};
